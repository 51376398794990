import React from "react"
import { Link } from "gatsby"

import ButtonCSS from "./mobile-backbutton.module.scss"

const MobileBackbutton = () => {

  function goBack(){
    window.history.back();
  }

  return(
    <div onClick={goBack} className={ButtonCSS.button}>
      <h3 className={ButtonCSS.buttonText}><p className="fa fa-angle-left"></p> Atpakaļ</h3>
    </div>
  )

}


export default MobileBackbutton
