import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MobileBackbutton from "../components/mobile-backbutton"
import DesktopBackbutton from "../components/desktop-backbutton"
import CategoryTitle from "../components/category/category-title"
import ProductBoxes from "../components/productlisting/product-boxes.js"

const ProductListing = ({ data }) => {

  const content = data.dataJson

  return(

    <Layout>
      <MobileBackbutton />
      <DesktopBackbutton background={content.headerBg} />
      <CategoryTitle title={content.title} desc={content.desc} />
      <ProductBoxes prefix={content.prefix} />
      <div style={{ height:"12vh" }}></div>
    </Layout>

  )

}

export default ProductListing

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      desc
      prefix
      headerBg
    }
  }
`
