import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import DynamicImage from "../dynamic-image"
import { Link } from "gatsby"

import ProductBoxCSS from "./product-box.module.scss"

const ProductBox = props => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: {eq: "product-fg.png"}) {
        childImageSharp {
          fluid(maxWidth: 807) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return(
    <div className={ProductBoxCSS.container}>
      <Link to={props.url}>
        <DynamicImage className={ProductBoxCSS.image} filename={props.thumbnail} />        
        {/*<Img className={ProductBoxCSS.image} fluid={data.background.childImageSharp.fluid} />*/}
        <p className={ProductBoxCSS.title}>{props.title}</p>
      </Link>
    </div>
  )
}

export default ProductBox
