import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import DynamicImage from "./dynamic-image"

import DesktopBackbuttonCSS from "./desktop-backbutton.module.scss"

export default props => {

  function goBack(){
    window.history.back();
  }

  return (
    <div onClick={goBack} className={DesktopBackbuttonCSS.parent} >
      <DynamicImage filename={props.background} class={DesktopBackbuttonCSS.image} alt="" />
      <div className={DesktopBackbuttonCSS.text}><span className="fa fa-angle-left"></span><p> Atpakaļ</p></div>
    </div>
  )

}
