import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ProductBoxesCSS from "./product-boxes.module.scss"

import ProductBox from "./product-box"

const ProductBoxes = props => {

  const query = useStaticQuery(graphql`
    query {
      allDataJson {
        edges {
          node {
            fields {
              slug
            }
            title
            thumbnail
            brand
            order
          }
        }
      }
    }
  `)

  const boxes = [];

  let edges = query.allDataJson.edges;

console.log("Bf", edges);

  edges = edges.sort(function(a, b){
              if(a.node.order < b.node.order){
                  return -1;
              }else if(a.node.order > b.node.order){
                  return 1;
              }
              return 0;
          });

  console.log("Af", edges);

  for(var i=0; i<edges.length; i++){
    var slug = edges[i].node.fields.slug
    var title = edges[i].node.title
    var thumbnail = edges[i].node.thumbnail
    var brand = edges[i].node.brand
    if(slug.indexOf("product-" + props.prefix) > -1) boxes.push(<ProductBox title={title} url={"/" + slug} thumbnail={"products/"+brand+"/"+thumbnail} />);
  }

  return(
    <div className={ProductBoxesCSS.container}>
      {boxes}
    </div>
  )

}

export default ProductBoxes
