import React from "react"

import CategoryTitleCSS from "./category-title.module.scss"

const CategoryTitle = props => (
  <div className={props.className}>
    <h3 className={CategoryTitleCSS.title}>{props.title}</h3>
    <div className={CategoryTitleCSS.separator}></div>
    <p className={CategoryTitleCSS.desc}>{props.desc}</p>
  </div>
)

export default CategoryTitle
